<template>
  <!-- 项目列表页 -->
  <v-container>
    <v-card elevation="0" class="indigo" style="background: linear-gradient(45deg, #03A9F4, #0000007d);">
      <!-- 标题 -->
      <v-card-title class="">
        <span>{{ title }}</span>
        <v-btn text class="white--text ml-3" @click="showOrderRecord = false">查看全部</v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="white--text" @click="newOrder()" v-if="
            role == 'customer' ||
              (role == 'projectAdmin' && referenceCode != '1000')
          ">新建
        </v-btn>
      </v-card-title>
    </v-card>
    <!-- 打开记录 -->
    <v-timeline align-top dense v-if="showOrderRecord">
      <v-timeline-item v-for="(val, key, index) in recentViewOrders" :key="index">
        <v-row dense class="mb-2">
          <v-col cols="12">
            <span class="title indigo--text font-weight-medium">{{ key }}</span>
          </v-col>
        </v-row>
        <div v-for="(record, orderIndex) in val" :key="orderIndex" class="mb-2">
          <v-card class="pa-2 order-item-hover" @click="orderDetail(record.order)">
            <v-row no-gutters align="center">
              <v-col cols="12" md="1">
                <v-img src="../../assets/scenes/project.png" max-height="40" max-width="40"></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <v-row no-gutters>
                  <v-col cols="12">
                    <span style="color:#283593">{{ record.order.name }}</span>
                  </v-col>

                  <v-col cols="12">
                    <span class="caption" style="font-weight:500">{{
                      record.order.username
                    }}</span>&nbsp;&nbsp;<span class="caption">{{
                      record.accessedTimeStr
                    }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="2">
                <!-- <span :style="{ color: getColor(record.order.status) }"
                  class="mr-2">{{ ordersStatusFormat(record.order.status) }}</span> -->
                  <!-- 订单步骤提示 -->
                  <span v-if="record.order.status == 'canceled'" style="color:red" class="mr-2">{{ ordersStatusFormat(record.order.status) }}</span>
                  <v-tooltip left v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <!-- <v-icon>mdi-format-list-numbered</v-icon> -->
                        <span :style="{ color: getColor(record.order.status) }" class="mr-2">{{ ordersStatusFormat(record.order.status) }}</span>
                      </span>
                    </template>
                    <div v-for="(text, index) in orderStatusArray" :key="index">
                      <div :style="{ opacity: getStatusStyleInStep(record.order.status, index) }">
                        <v-icon size="14" color="white">{{ getStatusStyleInStep(record.order.status, index)==1 ? 'mdi-check' : 'mdi-close'}}</v-icon>
                        <span>{{index+1}}.{{ordersStatusFormat(text)}}</span>
                      </div>
                    </div>
                  </v-tooltip>
                <v-btn color="error" small v-if="
                    record.order.status == 'canceled' &&
                      record.order.username == loginUsername
                  " @click.stop.prevent="deleteOrder(record.order.id)">删除</v-btn>
                <span class="caption" v-if="
                    record.order.status == 'arrived' &&
                      ((role == 'projectAdmin' && referenceCode == '1000') ||
                        role == 'sysAdmin')
                  ">{{ record.order.assignedSpecialist }}</span>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-timeline-item>
    </v-timeline>
    <!-- 全部项目 -->
    <div v-if="!showOrderRecord">
      <v-row align="center">
        <v-col cols="12" sm="1">
          <v-icon @click="showOrderRecord = true" color="teal" large>mdi-keyboard-backspace</v-icon>
        </v-col>
        <v-col cols="12" sm="6" offset="5">
          <v-text-field v-model="orderSearch" solo dense class="" append-icon="mdi-magnify" label="Search" single-line
            hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-data-table style="" :headers="headers" :items="orders" :search="orderSearch" class="order-table-hover"
        @click:row="orderDetail">
        <template v-slot:[`item.status`]="{ item }">
          <td>
            <v-chip :color="getColor(item.status)" dark>
              {{ ordersStatusFormat(item.status) }}
            </v-chip>
          </td>
        </template>
      </v-data-table>
    </div>

    <!-- 通知工具 -->
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" :timeout="2000" :top="true">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios"; //基于Promise的HTTP客户端
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl,
  version = commonCfg.urlVersion; //http://192.168.1.109:64011/
export default {
  data () {
    return {
      //tips
      showSnackbar: false,
      snackbarColor: "error",
      snackbarText: "",
      title: "项目列表",
      showOrderRecord: true,
      orders: [], //订单列表
      recentViewOrders: {}, //最近查看过的订单
      orderSearch: "", //订单表格搜索信息
      //订单表格header
      headers: [
        {
          text: "项目描述",
          align: "start",
          value: "name"
        },
        {
          text: "用户名",
          value: "username"
        },
        { text: "创建时间", value: "createdTime" },
        { text: "项目状态", value: "status" }
      ],
      referenceCode: "", //邀请码
      loginUsername: localStorage.getItem("username"),
      orderStatusArray: ['new', 'approval', 'offered', 'sign', 'payed', 'arrived', 'finished']//订单状态数组
    };
  },
  async created () {
    this.getRecentViewOrders();

    this.getOrderList();

    if (this.role == "projectAdmin") this.getReferenceCode();
  },
  props: ["role", "username"],
  methods: {
    //通知提示工具
    tips (text, color) {
      this.snackbarText = text;
      this.showSnackbar = true;
      this.snackbarColor = color;
    },
    //获得订单浏览记录
    async getRecentViewOrders () {
      let res = await axios.get(`${commonUrl}getRecentViewOrders${version}`);
      console.log(res.data);
      let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      let todayRecords = [],
        yesterdayRecords = [],
        sevendayRecords = [],
        earlyRecords = [];
      this.recentViewOrders = {};
      for (let order of res.data) {
        let accessedTime = new Date(order.viewTime);
        if (accessedTime.toDateString() == new Date().toDateString()) {
          //今天
          todayRecords.push(order);
        } else if (accessedTime.toDateString() == yesterday.toDateString()) {
          //昨天
          yesterdayRecords.push(order);
        } else if (
          accessedTime.getTime() >=
          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        ) {
          //7天内
          sevendayRecords.push(order);
        } else {
          earlyRecords.push(order);
        }

        order.accessedTimeStr = commonCfg.dateToString(accessedTime);
      }
      if (todayRecords.length > 0) this.recentViewOrders["今天"] = todayRecords;
      if (yesterdayRecords.length > 0)
        this.recentViewOrders["昨天"] = yesterdayRecords;
      if (sevendayRecords.length > 0)
        this.recentViewOrders["7天内"] = sevendayRecords;
      if (earlyRecords.length > 0) this.recentViewOrders["更早"] = earlyRecords;
    },
    //新建订单
    newOrder () {
      axios
        .get(`${commonUrl}listOneUser${version}`)
        .then(res => {
          console.log(res.data);
          if (res.data.result.applyStatus == "pass") {
            this.$router.push({
              name: "Detail",
              params: { orderId: -1 }
            });
          } else {
            this.tips("请先进行身份验证", "error");
          }
        })
        .catch(error => {
          console.log(error);
          this.tips("error", "error");
        });
    },
    //获得订单列表
    async getOrderList () {
      let res = await axios.get(`${commonUrl}getOrderList${version}`);
      for (let order of res.data) {
        order["createdTime"] = commonCfg.dateToString(
          new Date(order.createdTime)
        );
      }
      this.orders = res.data;
      console.log(this.orders);
    },
    //跳转到订单详情页
    async orderDetail (order) {
      console.log(order);
      await this.updateViewTime(order.id);
      this.$router.push({
        name: "Detail",
        params: { orderId: order.id }
      });
    },
    //订单状态颜色
    getColor (status) {
      let color = "green";
      if (status == "new") {
        color = "grey";
      } else if (status == "canceled") {
        color = "red";
      }
      return color;
    },
    //获得订单步骤中的透明度样式
    getStatusStyleInStep (orderStatus, stepIndex) {
      let opacity = 1
      let orderStatusIndex = this.orderStatusArray.findIndex(value => value == orderStatus)
      if(stepIndex > orderStatusIndex){
        opacity = 0.4
      }
      return opacity
    },
    //订单状态转化
    ordersStatusFormat (status) {
      let text = "";
      switch (status) {
        case "canceled":
          text = "已取消";
          break;
        case "new":
          text = "新建";
          break;
        case "approval":
          text = "已审批";
          break;
        case "offered":
          text = "已报价";
          break;
        case "sign":
          text = "已签约";
          break;
        case "payed":
          text = "已付款";
          break;
        case "arrived":
          text = "处理中";
          break;
        case "finished":
          text = "已完成";
          break;
        default:
          text = "未知";
      }
      return text;
    },
    //更新订单查看时间
    async updateViewTime (id) {
      let res = await axios.post(`${commonUrl}updateOrderViewTime${version}`, {
        viewTime: new Date().getTime(),
        id: id
      });
      console.log(res.data);
    },
    //删除订单
    deleteOrder (orderId) {
      this.$dialog
        .confirm({
          text: "删除后不可恢复，请谨慎操作！",
          title: "确认删除？",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.get(`${commonUrl}deleteOrderById${version}`, {
            params: { id: orderId }
          });
          if (res.data == "ok") {
            this.getRecentViewOrders();
            this.getOrderList();
            this.tips("删除成功", "success");
          } else {
            this.tips("删除失败", "error");
          }
        });
    },
    //获得邀请码
    async getReferenceCode () {
      let res = await axios.get(`${commonUrl}getReferenceCode${version}`);
      console.log(res.data);
      this.referenceCode = res.data;
    }
  }
};
</script>
<style scoped>
/* 可以在选择器中使用/deep/或>>>来创建应用于子组件内部元素的样式规则 */
.order-item-hover:hover {
  cursor: pointer;
  background-color: rgba(229, 194, 218, 0.1);
}
::v-deep .order-table-hover tbody tr:hover {
  cursor: pointer;
}
::v-deep .order-table-hover tbody tr:nth-of-type(odd) {
  background-color: rgba(229, 194, 218, 0.1);
}
</style>
