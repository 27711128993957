var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"indigo",staticStyle:{"background":"linear-gradient(45deg, #03A9F4, #0000007d)"},attrs:{"elevation":"0"}},[_c('v-card-title',{},[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('v-btn',{staticClass:"white--text ml-3",attrs:{"text":""},on:{"click":function($event){_vm.showOrderRecord = false}}},[_vm._v("查看全部")]),_c('v-spacer'),(
          _vm.role == 'customer' ||
            (_vm.role == 'projectAdmin' && _vm.referenceCode != '1000')
        )?_c('v-btn',{staticClass:"white--text",attrs:{"text":""},on:{"click":function($event){return _vm.newOrder()}}},[_vm._v("新建 ")]):_vm._e()],1)],1),(_vm.showOrderRecord)?_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.recentViewOrders),function(val,key,index){return _c('v-timeline-item',{key:index},[_c('v-row',{staticClass:"mb-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"title indigo--text font-weight-medium"},[_vm._v(_vm._s(key))])])],1),_vm._l((val),function(record,orderIndex){return _c('div',{key:orderIndex,staticClass:"mb-2"},[_c('v-card',{staticClass:"pa-2 order-item-hover",on:{"click":function($event){return _vm.orderDetail(record.order)}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-img',{attrs:{"src":require("../../assets/scenes/project.png"),"max-height":"40","max-width":"40"}})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#283593"}},[_vm._v(_vm._s(record.order.name))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"caption",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(record.order.username))]),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(record.accessedTimeStr))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[(record.order.status == 'canceled')?_c('span',{staticClass:"mr-2",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.ordersStatusFormat(record.order.status)))]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',{staticClass:"mr-2",style:({ color: _vm.getColor(record.order.status) })},[_vm._v(_vm._s(_vm.ordersStatusFormat(record.order.status)))])])]}}],null,true)},_vm._l((_vm.orderStatusArray),function(text,index){return _c('div',{key:index},[_c('div',{style:({ opacity: _vm.getStatusStyleInStep(record.order.status, index) })},[_c('v-icon',{attrs:{"size":"14","color":"white"}},[_vm._v(_vm._s(_vm.getStatusStyleInStep(record.order.status, index)==1 ? 'mdi-check' : 'mdi-close'))]),_c('span',[_vm._v(_vm._s(index+1)+"."+_vm._s(_vm.ordersStatusFormat(text)))])],1)])}),0),(
                  record.order.status == 'canceled' &&
                    record.order.username == _vm.loginUsername
                )?_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteOrder(record.order.id)}}},[_vm._v("删除")]):_vm._e(),(
                  record.order.status == 'arrived' &&
                    ((_vm.role == 'projectAdmin' && _vm.referenceCode == '1000') ||
                      _vm.role == 'sysAdmin')
                )?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(record.order.assignedSpecialist))]):_vm._e()],1)],1)],1)],1)})],2)}),1):_vm._e(),(!_vm.showOrderRecord)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-icon',{attrs:{"color":"teal","large":""},on:{"click":function($event){_vm.showOrderRecord = true}}},[_vm._v("mdi-keyboard-backspace")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","offset":"5"}},[_c('v-text-field',{attrs:{"solo":"","dense":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.orderSearch),callback:function ($$v) {_vm.orderSearch=$$v},expression:"orderSearch"}})],1)],1),_c('v-data-table',{staticClass:"order-table-hover",attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.orderSearch},on:{"click:row":_vm.orderDetail},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.ordersStatusFormat(item.status))+" ")])],1)]}}],null,true)})],1):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":2000,"top":true},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }